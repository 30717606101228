import React from 'react';
import FrameProvider from './Iframe/FrameProvider';
import Widget from './components/Widget';
import { ScopeProvider } from './Iframe/ScopeProvider';
import { connect } from 'redux-zero/react';
import actions from './store/actions/widgetState';
import './sdk';

import { initWidget } from './init';

initWidget();

function App({ widgetConfig, hidden }) {
  return (
    <React.StrictMode>
      {widgetConfig && !hidden && (
        <ScopeProvider scope={'#chatim-container'}>
          <FrameProvider>
            <Widget />
          </FrameProvider>
        </ScopeProvider>
      )}
    </React.StrictMode>
  );
}

export default connect(({ widgetConfig, hidden }) => ({ widgetConfig, hidden }), actions)(App);
