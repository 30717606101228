if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}
import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'redux-zero/react';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from './emotionCache';
import store from './store/';
import { createRoot } from 'react-dom/client';

const emotionCache = createEmotionCache();

function Widget() {
  return (
    <React.StrictMode>
      <CacheProvider value={emotionCache}>
        <Provider store={store}>
          <App />
        </Provider>
      </CacheProvider>
    </React.StrictMode>
  );
}

let container = document.getElementById('chatim-container');
const demo = window.chatimSettings.demo;
if (demo && container) {
  const root = createRoot(container);
  root.render(<Widget />);
} else {
  container = document.createElement('div');
  container.setAttribute('id', 'chatim-container');
  document.body.appendChild(container);
  const root = createRoot(container);
  root.render(<Widget />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
