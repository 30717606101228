import React, { useEffect } from 'react';
import { CacheProvider, keyframes, css } from '@emotion/react';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import styled from '@emotion/styled';
import { connect } from 'redux-zero/react';
import actions from '../store/actions/widgetState';
import HelloMsg from '../components/HelloMsg';

const apear = keyframes`
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  `;

const mapToProps = ({ widgetDisplayStatus, widgetWasOpenedBefore, widgetConfig }) => ({
  widgetDisplayStatus,
  widgetWasOpenedBefore,
  widgetConfig,
});

let memoizedCreateCacheWithContainer = weakMemoize((container) => {
  let newCache = createCache({ container, key: 'memoize' });
  return newCache;
});

const Context = (props) => (
  <FrameContextConsumer>
    {({ document }) => {
      return <CacheProvider value={memoizedCreateCacheWithContainer(document.head)}>{props.children}</CacheProvider>;
    }}
  </FrameContextConsumer>
);

const FrameProvider = ({ widgetDisplayStatus, children, widgetConfig }) => {
  const widgetFrame = css`
    z-index: 2147483000;
    position: ${window.chatimSettings && window.chatimSettings.demo ? '' : 'fixed'};
    border-style: none;
    // animation: 250ms ease 0s 1 normal none running ${window.chatimSettings && window.chatimSettings.demo
      ? ''
      : apear};
    transition: opacity 0.3s ease 0s, width 0.2s, bottom 0.2s;
    bottom: ${widgetConfig.ui.bottomMarginPx}px;
    ${widgetConfig.ui.position || 'right'}: ${widgetConfig.ui.rightMarginPx}px;
  `;

  let WidgetClosed = styled(Frame)`
    ${widgetFrame};
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `;

  let WidgetOpened = styled(Frame)`
    animation: 250ms ease 0s 1 normal none running ${window.chatimSettings && window.chatimSettings.demo ? '' : apear};
    transition: opacity 0.3s ease 0s, width 0.2s, bottom 0.2s;
    height: 100%;
    width: 100%;
    min-height: 550px;
    position: ${window.chatimSettings && window.chatimSettings.demo ? 'relative' : 'absolute'};
    padding: 0;
    margin: 0;
    border: none;
    left: 0;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `;

  let OpenWidgetWrapper = styled.div`
    min-height: 300px;
    overflow: hidden;
    opacity: 1;
    padding: 0;
    z-index: 2147483000;
    width: 100%;
    max-height: 650px;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0px;
    position: ${window.chatimSettings && window.chatimSettings.demo ? '' : 'fixed'};
    width: 430px;
    bottom: ${widgetConfig.ui.bottomMarginPx}px;
    ${widgetConfig.ui.position || 'right'}: ${widgetConfig.ui.rightMarginPx}px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      max-height: none;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      box-shadow: none;
    }
    & iframe {
      bottom: 0;
      right: 0;
    }
  `;

  return (
    <>
      <div>
        {widgetDisplayStatus === 'closed' ? (
          <>
            <WidgetClosed id='chatim-widget-iframe' head={<meta charset='UTF-8' />}>
              <Context>{children}</Context>
            </WidgetClosed>
            {widgetConfig.helloMessage.show && <HelloMsg />}
          </>
        ) : (
          <OpenWidgetWrapper onClick={(e) => getInnerHTML(e)}>
            <WidgetOpened
              id='chatim-widget-iframe'
              allow='fullscreen'
              head={
                <>
                  <meta charset='UTF-8' />
                  <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no'
                  />
                </>
              }
            >
              <Context>{children}</Context>
            </WidgetOpened>
          </OpenWidgetWrapper>
        )}
      </div>
    </>
  );
};

export default connect(mapToProps, actions)(FrameProvider);
