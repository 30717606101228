import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

export const createEmotionCache = () => {
  return createCache({
    key: 'chatim-widget-styles',
    prepend: true,
    stylisPlugins: [prefixer],
    container: document.getElementById('chatim-container'),
  });
};
