import store from './store';

console.log('chatim version: ', process.env.APP_VERSION);

window.chatimWidget = {
  open: () => {
    store.setState({
      widgetDisplayStatus: 'opened',
    });
  },

  close: () => {
    store.setState({
      widgetDisplayStatus: 'closed',
    });
  },

  setWidgetConfig: (widgetConfig) => {
    store.setState({
      widgetConfig,
    });
  },
};
