import socket from './lib/socket';
import store from './store';
import Cookies from 'js-cookie';
import { userInfo } from './lib/userInfo';

const initWidget = () => {
  let uniqueView = Cookies.get('unique-view');
  if (typeof uniqueView === 'undefined') {
    Cookies.set('unique-view', false, { expires: 1 });
    uniqueView = 'true';
  }
  const chatUuid = localStorage.getItem('chatim-chat-uuid');
  socket.emit(
    'widget_connect',
    {
      projectUuid: window.chatimSettings.projectId,
      uniqueView,
      userInfo,
      chatUuid,
    },
    (response) => {
      if (!chatUuid) {
        localStorage.setItem('chatim-chat-uuid', response.chatUuid);
      }
      store.setState({
        widgetConfig: response.widgetConfig,
        uniqueView,
      });
    },
  );
};

export { initWidget };
